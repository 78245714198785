export const SET_TASKS = 'SET_TASKS';
export const UPDATE_NEW_TASK = 'UPDATE_NEW_TASK';
export const ADD_TASK = 'ADD_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const START_EDITING_TASK = 'START_EDITING_TASK';
export const FINISH_EDITING_TASK = 'FINISH_EDITING_TASK';
export const UPDATE_TASK_TITLE = 'UPDATE_TASK_TITLE';

export const UPDATE_TEXT_AREA = 'UPDATE_TEXT_AREA';

export const setTasks = (tasks) => ({
    type: SET_TASKS,
    payload: tasks,
});

export const updateTextArea = (newText) => ({
    type: UPDATE_TEXT_AREA,  // Используйте новый тип
    payload: newText,
});

export const updateTaskText = (taskId, newText) => ({
    type: 'UPDATE_TASK_TEXT',
    payload: { taskId, newText },
});