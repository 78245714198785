import React, { useEffect, useState } from 'react';
import './App.css';
import { connect } from 'react-redux';
import * as actionTypes from './redux/actions';
import discord from './discord.png';
import telegram from './telegram.png';
import phone from './phone.png';
import instagram from './instagram.png';

const TodoList = (props) => {
    const { newTask, tasks, dispatch } = props;
    const [initialized, setInitialized] = useState(false);
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [textAreaText, setTextAreaText] = useState('');
    const [taskToEdit, setTaskToEdit] = useState(null);

    useEffect(() => {
        if (!initialized) {
            const storedTasks = localStorage.getItem('tasks');
            console.log('Stored tasks:', storedTasks);

            if (storedTasks) {
                dispatch({ type: actionTypes.SET_TASKS, payload: JSON.parse(storedTasks) });
            }

            setInitialized(true);
        }
    }, [dispatch, initialized]);

    const addTask = () => {
        if (newTask.trim() !== '') {
            const updatedTasks = [...tasks, { id: Date.now(), title: newTask, textAreaText: '' }];
            localStorage.setItem('tasks', JSON.stringify(updatedTasks));
            console.log('Tasks after adding:', updatedTasks);
            dispatch({ type: actionTypes.ADD_TASK, payload: newTask });
        }
    };

    const deleteTask = (taskId) => {
        const updatedTasks = tasks.filter(task => task.id !== taskId);
        localStorage.setItem('tasks', JSON.stringify(updatedTasks));
        console.log('Tasks after deletion:', updatedTasks);
        dispatch({ type: actionTypes.DELETE_TASK, payload: taskId });
    };

    useEffect(() => {
        if (taskToEdit) {
            setTextAreaText(taskToEdit.title);
        }
    }, [editingTaskId, taskToEdit]);
    const startEditingTask = (taskId) => {
        const taskToEdit = tasks.find((task) => task.id === taskId);

        if (taskToEdit) {
            setEditingTaskId(taskId);
            setTaskToEdit(taskToEdit);
            setTextAreaText(taskToEdit.title);
        }
    };

    const finishEditingTask = () => {
        console.log('editingTaskId:', editingTaskId);
        console.log('textAreaText:', textAreaText);
        const updatedTasks = tasks.map(task =>
            task.id === editingTaskId ? { ...task, title: textAreaText, textAreaText: textAreaText } : task
        );
        localStorage.setItem('tasks', JSON.stringify(updatedTasks));
        console.log('Tasks in localStorage:', JSON.parse(localStorage.getItem('tasks')));
        dispatch({ type: 'UPDATE_TASK_TEXT', payload: { taskId: editingTaskId, newText: textAreaText } });
        dispatch({ type: 'FINISH_EDITING_TASK' });
        setEditingTaskId(null); // Сбрасываем editingTaskId после сохранения изменений
        setTextAreaText(''); // Сбрасываем textAreaText после сохранения изменений
    };


    return (
        <div className={"container"}>
            <div>
                <input
                    type="text"
                    value={newTask}
                    onChange={e => dispatch({ type: actionTypes.UPDATE_NEW_TASK, payload: e.target.value })}
                    placeholder="Enter task title"
                    className={"add-items"}
                />
                <button onClick={addTask} className={"add-button"}>
                    +
                </button>
            </div>
            <div className={"list"}>
                {tasks.map(task => (
                    <div key={task.id} className={"list-item neon-border"}>
                        {editingTaskId === task.id ? (
                            <div>
                                <textarea
                                    value={textAreaText}
                                    onChange={(e) => setTextAreaText(e.target.value)}
                                    autoFocus
                                    className={"edit-field"}
                                />
                                <button
                                    onClick={finishEditingTask}
                                    className={"done-button"}
                                >
                                    &#10003;
                                </button>
                            </div>
                        ) : (
                            <div>
                                <span>{task.title}</span>
                                <button
                                    onClick={() => startEditingTask(task.id)}
                                    className={"edit-button"}
                                >
                                    <span>&#9998;</span>
                                </button>
                            </div>
                        )}
                        <button
                            onClick={() => deleteTask(task.id)}
                            className={"delete-button"}
                        >
                            X
                        </button>
                    </div>
                ))}
            </div>
            <div className="contact">
                <div className="contact-list">
                    <a href="youtube.com" className="contact-item">
                        <img src={discord} alt="" />
                    </a>
                    <a href="youtube.com" className="contact-item">
                        <img src={instagram} alt="" />
                    </a>
                    <a href="youtube.com" className="contact-item">
                        <img src={phone} alt="" />
                    </a>
                    <a href="youtube.com" className="contact-item">
                        <img src={telegram} alt="" />
                    </a>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    tasks: state.tasks,
    newTask: state.newTask,
    editingTaskId: state.editingTaskId,
    editTaskTitle: state.editTaskTitle,
});

export default connect(mapStateToProps)(TodoList);
