import * as actionTypes from './actions';
// import {SET_TASKS} from "./actions";

const initialState = {
    tasks: [],
    newTask: '',
    editingTaskId: null,
    editTaskTitle: '',
    selectedTaskId: null,
    textAreaText: '',
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_NEW_TASK:
            // Обработка обновления новой задачи
            return {
                ...state,
                newTask: action.payload,
            };
        case actionTypes.ADD_TASK:
            // Обработка добавления задачи
            return {
                ...state,
                tasks: [...state.tasks, { id: Date.now(), title: action.payload }],
                newTask: '',
            };
        case actionTypes.DELETE_TASK:
            // Обработка удаления задачи
            return {
                ...state,
                tasks: state.tasks.filter(task => task.id !== action.payload),
            };
        case actionTypes.START_EDITING_TASK:
            return {
                ...state,
                editingTaskId: action.payload.taskId,
                textAreaText: action.payload.text,
            };
        case actionTypes.SET_TASKS:
            // Обработка установки задач
            return {
                ...state,
                tasks: action.payload,
            };
        case 'UPDATE_TEXT_AREA':
            return {
                ...state,
                tasks: state.tasks.map((task) =>
                    task.id === action.payload.taskId ? { ...task, textAreaText: action.payload.newText } : task
                ),
                textAreaText: action.payload.newText,
            };
        case 'UPDATE_TASK_TEXT':
            const updatedTasks = state.tasks.map((task) =>
                task.id === action.payload.taskId ? { ...task, title: action.payload.newText } : task
            );
            return {
                ...state,
                tasks: updatedTasks,
                editingTaskId: null,
                textAreaText: '', // Обнуляем textAreaText после сохранения изменений
            };
        case actionTypes.FINISH_EDITING_TASK:
            // Обработка завершения редактирования задачи
            return {
                ...state,
                editingTaskId: null,
            };
        case actionTypes.UPDATE_TASK_TITLE:
            // Обработка обновления заголовка задачи
            return {
                ...state,
                tasks: state.tasks.map(task =>
                    task.id === action.payload.id ? { ...task, title: action.payload.title } : task
                ),
                editingTaskId: null,
            };
        default:
            return state;
    }
};

export default rootReducer;
